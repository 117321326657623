/* eslint-disable react/no-deprecated */
// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/store';

// ** External Packages
import { registerLicense } from '@syncfusion/ej2-base';

// ** Intl & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';
import './assets/scss/user-profile/session.scss';
import './assets/scss/syncfusionStyle.scss';
import './assets/scss/tracking/tracking-module.scss';

// HELLO-DESIGN ----------------------- START
// import './assets/scss/new/common.scss';
// import './assets/scss/new/layout.scss';
// import './assets/scss/new/user-guide.scss';
// import './assets/scss/new/responsive.scss';
// import './assets/scss/new/auth.scss';
// import './assets/scss/new/company-forms.scss';
// import './assets/scss/new/dashboard.scss';
// import './assets/scss/new/direct-mail.scss';

// import './assets/scss/new/email-module.scss';
// import './assets/scss/new/company-detail.scss';
// import './assets/scss/new/email-template-page.scss';
// import './assets/scss/new/envelope.scss';
// import './assets/scss/new/mass-email.scss';
// import './assets/scss/new/event-page.scss';
// import './assets/scss/new/inventory-module.scss';
// import './assets/scss/new/feature-request.scss';
// import './assets/scss/new/pipeline-page.scss';
// import './assets/scss/new/print.scss';
// import './assets/scss/new/report-problem.scss';
// import './assets/scss/new/settings.scss';
// import './assets/scss/new/single-note-page.scss';
// import './assets/scss/new/task-manager.scss';

// HELLO-DESIGN ----------------------- END

// ** Service Worker
// import * as serviceWorker from './serviceWorker';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENCE_KEY);
// require("dotenv").config();

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
